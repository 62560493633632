import { useContext, useState } from 'react'
import MuiPhoneNumber from 'material-ui-phone-number'
import { Box, Button, Dialog, Divider, Grid, IconButton, List, ListItem, ListItemText, TextField, Typography } from '@mui/material'
import { Clear } from '@mui/icons-material'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'
import { StoreContext } from '../../../../utilities/contexts/StoreContext'
import { validateAddCustomStopForm } from './AddCustomeStopValidation'
import { RouteBuilderService } from '../../../../utilities/services/RouteBuilderService'
import { CustomStopDetail } from '../../../../utilities/types/DeliveryRouteTypes'
import language from '../../../../language/language'
import addCustomStopStyles from './AddCustomStopStyles'

type CustomStopProps = {
    openCustomStop: boolean
    setOpenCustomStop: (value: boolean) => void
    setCustomStopDetails: (detail: CustomStopDetail[]) => void
    customStopDetails: CustomStopDetail[]
    setOpenEmptyModal: (value: boolean) => void
    isEditModal: boolean
}

const AddCustomStop = ({
    openCustomStop,
    setOpenCustomStop,
    setCustomStopDetails,
    customStopDetails,
    setOpenEmptyModal,
    isEditModal
}: CustomStopProps) => {
    const { currentLanguage } = useContext(LanguageContext)
    const { currentStore } = useContext(StoreContext)
    const [addressLine, setAddressLine] = useState<string>('')
    const [stopName, setStopName] = useState<string>('')
    const [city, setCity] = useState<string>('')
    const [state, setState] = useState<string>('')
    const [zipCode, setZipCode] = useState<string>('')
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [deliveryNote, setDeliveryNote] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [lat, setLat] = useState<number | undefined>(undefined)
    const [long, setLong] = useState<number | undefined>(undefined)
    const [suggestions, setSuggestions] = useState<[]>([])

    const handleClose = () => {
        setAddressLine('')
        setStopName('')
        setCity('')
        setState('')
        setZipCode('')
        setPhoneNumber('')
        setDeliveryNote('')
        setLat(undefined)
        setLong(undefined)
        setOpenCustomStop(false)
    }

    const handleAddToRouteOnPress = async () => {
        const details = {
            storeNumber: currentStore,
            stopName: stopName,
            addressLine1: addressLine,
            zipCode: zipCode,
            city: city,
            state: state,
            latitude: lat,
            longitude: long,
            stopNotes: deliveryNote,
            creationTimestamp: null,
            addressLine2: '',
            name: name
        }
        if (customStopDetails?.length > 0)
            setCustomStopDetails([...customStopDetails, details])
        else
            setCustomStopDetails([details])

        handleClose()
        !isEditModal && setOpenEmptyModal(true)
    }

    const isAddToRouteButtonDisabled = !validateAddCustomStopForm(addressLine, stopName, city, state, zipCode, phoneNumber)

    const handlePhoneChange = (event: any) => {
        let validPhone = event.replace(/[{()}-]/g, '')
        validPhone = validPhone.replaceAll(' ', '')
        setPhoneNumber(validPhone)
    }

    const handleSelect = (selectedplace: any) => {
        const context = selectedplace.context || []
        const city = context.find((c: any) => c.id.includes('place'))?.text || ''
        const state = context.find((c: any) => c.id.includes('region'))?.short_code || ''
        const postalCode = context.find((c: any) => c.id.includes('postcode'))?.text || ''
        const local = selectedplace?.properties?.address
        setAddressLine([selectedplace?.address, selectedplace.text, local && `, ${local}`].filter(Boolean).join(' '))
        setName([selectedplace.text, local && `, ${local}`].filter(Boolean).join(' '))
        setCity(city)
        setState(state.split('US-')[1])
        setZipCode(postalCode)
        setLat(selectedplace.center[1])
        setLong(selectedplace.center[0])
        setSuggestions([])
    }

    const queryGeocoding = async (input: string) => {
        if (input.length > 2) {
            const response = await RouteBuilderService.getGeocodingAddress(input)
            setSuggestions(response?.features ?? [])
        } else {
            setSuggestions([])
        }
    }

    return (
        <Dialog open={openCustomStop} onClose={handleClose} sx={addCustomStopStyles.customStopModal}>
            <Grid container padding={'10px'} sx={addCustomStopStyles.customStopModalContainer}>
                <Grid sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Typography sx={addCustomStopStyles.modalTitle}>
                        {language[currentLanguage].customStop}
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <Clear fontSize='medium' />
                    </IconButton>
                </Grid>
                <Grid sx={addCustomStopStyles.addressContainer}>
                    <Button
                        variant={'outlined'}
                        color="inherit"
                        disableElevation
                        sx={addCustomStopStyles.newAddress}
                    >
                        {language[currentLanguage].newAddress}
                    </Button>
                    {/* <Button
                        variant={'outlined'}
                        color="inherit"
                        disableElevation
                        sx={addCustomStopStyles.newAddress}
                    >
                        Saved Address
                    </Button> */}
                </Grid>
                <Box width={'100%'}>
                    <Typography sx={addCustomStopStyles.customStopLabel}>{language[currentLanguage].stopName}</Typography>
                    <TextField
                        sx={addCustomStopStyles.customStopTextField}
                        inputProps={{ 'data-testid': 'stop-name', maxLength: 50 }}
                        type="text"
                        value={stopName}
                        onChange={(stopName) => setStopName(stopName.target.value)}
                        helperText={`${50 - (stopName?.length || 0)} characters remaining`}
                        FormHelperTextProps={{
                            sx: addCustomStopStyles.helperText
                        }}
                    />
                </Box>
                <Box width={'100%'}>
                    <Typography sx={addCustomStopStyles.customStopLabel}>{language[currentLanguage].addressLine1}</Typography>
                    <TextField
                        fullWidth
                        inputProps={{ 'data-testid': 'address-line1', 'position': 'relative', 'autoComplete': 'off', 'margin-bottom': 0 }}
                        type="text"
                        value={addressLine}
                        autoComplete='off'
                        onChange={(addressLine) => {
                            setAddressLine(addressLine.target.value)
                            queryGeocoding(addressLine.target.value)
                        }}
                    />
                    {suggestions?.length > 0 && (
                        <Box
                            className='mapbox-response'
                            sx={addCustomStopStyles.mapboxResponse}
                        >
                            <List>
                                {suggestions.map((place: any) => (
                                    <><ListItem
                                        key={place.id}
                                        onClick={() => handleSelect(place)}
                                    >
                                        <ListItemText primary={place.place_name} />
                                    </ListItem>
                                        <Divider component="li" />
                                    </>
                                ))}
                            </List>
                        </Box>
                    )}
                </Box>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Typography sx={addCustomStopStyles.customStopLabel}>{language[currentLanguage].city}</Typography>
                        <TextField
                            inputProps={{ 'data-testid': 'city' }}
                            type='text'
                            style={{ width: '100%' }}
                            value={city}
                            onChange={(city) => setCity(city.target.value)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Typography sx={addCustomStopStyles.customStopLabel}>{language[currentLanguage].state}</Typography>
                        <TextField
                            inputProps={{ 'data-testid': 'state' }}
                            type='text'
                            value={state}
                            onChange={(state) => setState(state.target.value)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography sx={addCustomStopStyles.customStopLabel}>{language[currentLanguage].zipCode}</Typography>
                        <TextField
                            inputProps={{ 'data-testid': 'zipCode' }}
                            type='text'
                            value={zipCode}
                            onChange={(zipCode) => setZipCode(zipCode.target.value)}
                        />
                    </Grid>
                </Grid>
                <Box width={'100%'}>
                    <Typography sx={addCustomStopStyles.customStopLabel}>{language[currentLanguage].phoneNumber}</Typography>
                    <MuiPhoneNumber
                        defaultCountry={'us'}
                        onChange={handlePhoneChange}
                        inputProps={{ 'data-testid': 'phonenumber' }}
                        onlyCountries={['us', 'ca']}
                        type={'text'}
                        countryCodeEditable={false}
                        disableAreaCodes
                        InputProps={{ disableUnderline: true, style: { margin: '0 12px' } }}
                        value={phoneNumber}
                        sx={{ ...addCustomStopStyles.phoneNumberField, borderColor: '#bfbfbf' }}
                    />
                </Box>
                <Box width={'100%'}>
                    <Typography sx={addCustomStopStyles.customStopLabel}>{language[currentLanguage].deliveryNote}</Typography>
                    <TextField
                        sx={addCustomStopStyles.customStopTextField}
                        multiline
                        inputProps={{ 'data-testid': 'delivery-note', 'maxLength': '500' }}
                        type="text"
                        placeholder={'ex: pick up a return part'}
                        value={deliveryNote}
                        onChange={(deliveryNote) => setDeliveryNote(deliveryNote.target.value)}
                        helperText={`${500 - (deliveryNote?.length || 0)} characters remaining`}
                        FormHelperTextProps={{
                            sx: addCustomStopStyles.helperText
                        }}
                    />
                </Box>
                <Button variant={'primary'} style={{ width: '100%' }} disabled={isAddToRouteButtonDisabled} onClick={handleAddToRouteOnPress}>
                    {language[currentLanguage].addToRoute}
                </Button>
                <Button sx={addCustomStopStyles.closeButton} onClick={handleClose}>
                    {language[currentLanguage].close}
                </Button>
            </Grid>
        </Dialog >
    )
}

export default AddCustomStop
