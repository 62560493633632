import React, { MutableRefObject, ReactElement, useContext, useEffect, useMemo, useRef, useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  IconButton
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {
  DataGrid,
  GridToolbarQuickFilter,
  gridVisibleSortedRowIdsSelector
} from '@mui/x-data-grid'
import { invoiceListStyles } from './invoiceListStyles'
import { RouteBuilderContext, RouteBuilderTab } from '../../RouteBuilder'
import {
  InvoicesResponse,
  DeliveryRouteService
} from '../../../../utilities/services/DeliveryRouteService'
import { SnackbarContext } from '../../../../utilities/contexts/SnackbarContext'
import { getFormattedDate, msToTime } from '../../../../utilities/helpers/DateTimeFormatters'
import Button from '@mui/material/Button'
import language from '../../../../language/language'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'
import Colors from '../../../../assets/Colors'
import OnHoldFlag from '../../../../assets/onhold.png'
import { AutoDispatchInfo } from '../AutoDispatchInfo/AutoDispatchInfo'
import CachedIcon from '@mui/icons-material/Cached'
import { RemoveCircleOutline, CheckOutlined, AddOutlined } from '@mui/icons-material'
import { StoreContext } from '../../../../utilities/contexts/StoreContext'
import { AutoDispatchDetain } from '../../../../utilities/types/DeliveryRouteTypes'
import { ReactComponent as RemoveIcon } from '../../../../assets/removeInvoice.svg'
import { ReactComponent as HoldIcon } from '../../../../assets/holdInvoice.svg'
import { ReactComponent as ReleaseIcon } from '../../../../assets/releaseInvoice.svg'
import { ReactComponent as WarningIcon } from '../../../../assets/warning.svg'
import { ReactComponent as CheckMarkIcon } from '../../../../assets/checkmark.svg'
import DateFilter from '../DateFilter/DateFilter'
import { makeEndDate } from "../DateFilter/Dates";
import { useFlags } from 'launchdarkly-react-client-sdk'
import { isApac } from '../../../../utilities/helpers/RegionHelper'

enum ActionType {
  remove = 'remove',
  markAsDelivered = 'markAsDelivered'
}

type BulkInvoices = {
  invoiceNumber: string
  invoiceDateTime: number
}

type InvoiceListProps = {
  invoices: InvoicesResponse[] | undefined
  isLoadingInvoices: boolean
  setStartAndEndDates: any
  startDate: number
  endDate: number
  isErrorInvoices: boolean
  fetchInvoices: () => void
  setInvoiceDetails: (value: any) => void
  setOpenEditInvoice: (value: boolean) => void
  setOpenInvoiceActions: (value: boolean) => void,
  setInvoiceActionType: (value: ActionType) => void
  setStartDateFilter: (value: number) => void
  setEndDateFilter: (value: number) => void
  currentTab: RouteBuilderTab | null,
  setOpenLocationUpdate: (value: boolean) => void
  setLocationInvoiceDetails: (value: any) => void
  setIsBuildRoute: (value: boolean) => void
  setManualAddInvoiceOpen: (value: boolean) => void
  bulkInvoices: BulkInvoices[] | []
  setBulkInvoices: (value: BulkInvoices[]) => void
  clearSelectedInvoiceData: () => void
}

const InvoiceList = ({
  invoices,
  isLoadingInvoices,
  setStartAndEndDates,
  startDate,
  endDate,
  isErrorInvoices,
  setStartDateFilter,
  setEndDateFilter,
  setInvoiceDetails,
  setLocationInvoiceDetails,
  setOpenEditInvoice,
  currentTab,
  fetchInvoices,
  setOpenInvoiceActions,
  setInvoiceActionType,
  setOpenLocationUpdate,
  setIsBuildRoute,
  setManualAddInvoiceOpen,
  bulkInvoices,
  setBulkInvoices,
  clearSelectedInvoiceData
}: InvoiceListProps) => {
  const { selectedInvoices, setSelectedInvoices, setFilteredInvoices } =
    useContext(RouteBuilderContext)
  const { addSnack } = useContext(SnackbarContext)
  const { isCustomStopEnabledApac } = useFlags()
  const { currentLanguage } = useContext(LanguageContext)
  const [displayInvoices, setDisplayInvoices] = useState<InvoicesResponse[] | null>()
  const timer: MutableRefObject<any> = useRef(null);
  const [needsRefresh, setNeedsRefresh] = useState<boolean>(false)
  const windowSize = useRef([window.innerWidth, window.innerHeight])
  const interacted = useRef<boolean>(false)
  const oneMin = 60000
  const fiveMin = 5 * oneMin

  const [stopAutoDash, setStopAutoDash] = useState<boolean>(true)
  const [nowAutoDash, setNowAutoDash] = useState<boolean>(true)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [selectedInvoiceData, setSelectedInvoiceData] = useState<InvoicesResponse | null>(null)
  const { storeAddress, currentStore, autoDispatchEnabled, isCompanyOwned } = useContext(StoreContext)
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedReason, setSelectedReason] = useState('')

  const handleOpenDialog = (): void => {
    setOpenDialog(true)
  }

  const handleCloseDialog = (): void => {
    setOpenDialog(false)
    setSelectedReason('')
  }

  const handleReasonChange = (event: { target: { value: React.SetStateAction<string> } }): void => {
    setSelectedReason(event.target.value)
  }

  const handleConfirmStopAutoDispatch = async (): Promise<void> => {
    handleCloseDialog()
    await stopAutoDispatch(selectedReason)
  }

  useEffect(() => {
    if (invoices) {
      //TODO: This is fixing the invoice list not being updated immediately // test
      setDisplayInvoices(invoices)
      if (interacted.current) {
        //TODO: Do we still need this popup?
        // addSnack({
        //   severity: 'info',
        //   message: 'New invoice data is available.',
        //   action: {
        //     prompt: 'refresh',
        //     callback: () => {
        interacted.current = false
        setDisplayInvoices(invoices)
        //   }
        // }
        // })
      } else {
        setDisplayInvoices(invoices)
      }
    } else {
      setDisplayInvoices(null)
    }
  }, [invoices, addSnack, displayInvoices])

  const startAutoDispatch = async (): Promise<void> => {
    const customerAddress = selectedInvoiceData?.deliveryAddress
    const request: AutoDispatchDetain = {
      storeNumber: currentStore,
      invoiceNumber: selectedInvoiceData?.invoiceNumber ?? '',
      invoiceDateTime: selectedInvoiceData?.invoiceDateTime.toString() ?? '',
      pickupInstructions: '',
      dropoffInstructions: '',
      pickupAddress: `${storeAddress.addressLine1} ${storeAddress.addressLine2} ${storeAddress.city}, ${storeAddress.state} ${storeAddress.zipCode}`,
      dropoffAddress: `${customerAddress?.addressLine1} ${!!customerAddress?.addressLine2 ? customerAddress.addressLine2 : ''} ${customerAddress?.city}, ${customerAddress?.state} ${customerAddress?.zipCode}`,
      dropoffPhoneNumber: `${customerAddress?.phoneNumber}`,
      orderValueInCents: '0',
      dropoffBusinessName: customerAddress?.name || '',
      isCOS: isCompanyOwned
    }
    const successMessage = `${(language as any)[currentLanguage].autoDispatched
      .split('#id')
      .join(selectedInvoiceData?.invoiceNumber)}`
    try {
      await DeliveryRouteService.createRouteAndDelivery(request)
      addSnack({
        severity: 'success',
        message: successMessage,
        action: null,
        duration: 3000
      })
      clearSelectedInvoiceData()
      fetchInvoices()
    } catch (error) {
      addSnack({
        severity: 'error',
        message: (language as any)[currentLanguage].autoDispatchedError,
        action: null,
        duration: 3000
      })
    }
  }

  const stopAutoDispatch = async (selectedReason: string): Promise<void> => {
    try {
      const request = {
        invoiceNumberDateTimes: bulkInvoices,
        nssaInvoiceStatus: 'INVOICED',
        reason: selectedReason,
        stopAutodispatch: true
      }
      await DeliveryRouteService.updateInvoiceStatus(request, currentStore)
      const successMessage = `${(language as any)[currentLanguage].stopAutoDispatched} ${bulkInvoices.map(invoice => invoice.invoiceNumber)}`
      addSnack({
        severity: 'success',
        message: successMessage,
        action: null,
        duration: 3000
      })
      setIsDisabled(true)
      clearSelectedInvoiceData()
      fetchInvoices()
    } catch (error: any) {
      addSnack({
        severity: 'error',
        message: (language as any)[currentLanguage].stopAutoDispatchedError,
        action: null,
        duration: 3000
      })
    }
  }

  const handleOnHold = async () => {
    const request = {
      invoiceNumberDateTimes: bulkInvoices,
      nssaInvoiceStatus: selectedInvoiceData?.nssaInvoiceStatus === 'ON_HOLD' ? 'INVOICED' : 'ON_HOLD',
      stopAutodispatch: false
    }
    try {
      await DeliveryRouteService.updateInvoiceStatus(
        request,
        currentStore
      )
      setOpenInvoiceActions(false)
      setInvoiceDetails(null)
      setIsDisabled(true)
      clearSelectedInvoiceData()
      addSnack({
        severity: 'success',
        message: `${(language as any)[currentLanguage].invoiceNumber}${bulkInvoices.map(invoice => invoice.invoiceNumber)} ${(language as any)[currentLanguage].hasUpdated}`,
        action: null,
        duration: 3000
      })
    } catch (error) {
      addSnack({
        severity: 'error',
        message: (language as any)[currentLanguage].errorMessage,
        action: null,
        duration: 3000
      })
    }
  }

  const onSearch = () => {
    const start = new Date(startDate)
    start.setHours(0, 0, 0, 0)
    const end = new Date(endDate)
    startDate && endDate && setStartAndEndDates(start.getTime(), makeEndDate(end.getTime()))
    setDisplayInvoices(null)
  }

  const resetAll = () => {
    const start = new Date(new Date().getTime() - 72 * 60 * 60 * 1000)
    start.setHours(0, 0, 0, 0)
    const end = new Date()
    setStartAndEndDates(start.getTime(), makeEndDate(end.getTime()))
  }

  const FlaggedCell = () => {
    const flagRef = useRef<HTMLImageElement | null>(null)

    return (
      <Tooltip
        title={language[currentLanguage].onHoldTooltip}
        arrow={true}
        placement={'top-start'}
        PopperProps={{ anchorEl: flagRef.current }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img ref={flagRef} src={OnHoldFlag} alt={''} width={20} height={20} />
          <Typography sx={invoiceListStyles.storeName}>
            {(language as any)[currentLanguage].onHold}
          </Typography>
        </Box>
      </Tooltip>
    )
  }

  function renderFlaggedCell() {
    return (params: any) => {
      if (params.row.invoiceDetails.nssaInvoiceStatus === 'ON_HOLD') {
        return <FlaggedCell />
      }
      return null
    }
  }

  const InvoiceDataGrid: ReactElement = useMemo(() => {

    //timer to refresh time elapsed column
    !!timer && !!timer.current && clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      setNeedsRefresh(!needsRefresh)
    }, 200000);

    const QuickSearchToolbar = () => {
      const [searchFilter, setSearchFilter] = useState<string>('')

      return (
        <Box sx={{ p: 0.5, pb: 0 }}>
          <Grid container spacing={2}>
            <Grid item style={invoiceListStyles.searchBarBox} md={5}>
              <GridToolbarQuickFilter
                sx={invoiceListStyles.searchBar}
                placeholder={(language as any)[currentLanguage].searchInvoice}
                quickFilterParser={(searchInput: string) => {
                  setSearchFilter(searchInput)
                  interacted.current = true
                  return searchInput
                    .split(' ')
                    .map((value) => value.trim())
                    .filter((value) => value !== '')
                }}
              />
            </Grid>
            <Grid item style={invoiceListStyles.searchBarBox}>
              <DateFilter
                startDate={startDate}
                setStartDateFilter={setStartDateFilter}
                endDate={endDate}
                setEndDateFilter={setEndDateFilter}
                onSearch={onSearch}
                resetAll={resetAll}
              />
            </Grid>
          </Grid>
          <Box sx={invoiceListStyles.filterContainer}>
            <Grid item container xs={12} sx={invoiceListStyles.dispatchButtonContainer} paddingBottom={'0px'}>
              <Grid item xs={5} display={'flex'} justifyContent={'start'}>
                {(!isApac || (isCustomStopEnabledApac && isApac)) && <Button
                  data-testid='insert-invoice'
                  onClick={() => {
                    setManualAddInvoiceOpen(true)
                  }}
                  sx={invoiceListStyles.insertInvoice}
                >
                  <AddOutlined fontSize="medium" />
                  {language[currentLanguage].insertInvoice}
                </Button>}
              </Grid>
              <Grid item xs={7} display={'flex'} justifyContent={'end'}>
                {autoDispatchEnabled &&
                  <>
                    <Button startIcon={<CachedIcon />} sx={invoiceListStyles.startAutoDispatchButton}
                      disabled={nowAutoDash} onClick={startAutoDispatch}>{(language as any)[currentLanguage].autoDispatchNow}</Button>
                    <Button disabled={stopAutoDash} startIcon={<RemoveCircleOutline />}
                      sx={invoiceListStyles.stopAutoDispatch}
                      onClick={handleOpenDialog}>{(language as any)[currentLanguage].stopDispatchNow}</Button>
                  </>}
                <Button
                  disabled={isDisabled}
                  data-testid='hold-invoice'
                  onClick={async () => {
                    await handleOnHold()
                    fetchInvoices()
                  }}
                  sx={invoiceListStyles.startAutoDispatchButton}>
                  {currentTab === 'holdInvoices' ? <ReleaseIcon style={{ fill: isDisabled ? Colors.napaGreyDisabled2 : Colors.napaYellow3 }} /> :
                    <HoldIcon style={{ fill: isDisabled ? Colors.napaGreyDisabled2 : Colors.black }} />}
                  {currentTab === 'holdInvoices'
                    ? (language as any)[currentLanguage].releaseHold
                    : (language as any)[currentLanguage].placeHold}
                </Button>
                <Button
                  disabled={isDisabled}
                  data-testid='mark-as-delivered'
                  onClick={() => {
                    setOpenInvoiceActions(true)
                    setInvoiceActionType(ActionType.markAsDelivered)
                  }}
                  sx={invoiceListStyles.startAutoDispatchButton}>
                  <CheckOutlined fontSize="medium" />
                  {(language as any)[currentLanguage].markDelivered}
                </Button>
                <Button
                  disabled={isDisabled}
                  data-testid='remove-invoice'
                  onClick={() => {
                    setOpenInvoiceActions(true)
                    setInvoiceActionType(ActionType.remove)
                  }} sx={invoiceListStyles.removeButton}>
                  <RemoveIcon style={{ fill: isDisabled ? Colors.napaGreyDisabled2 : Colors.napaRed3 }} />
                  {(language as any)[currentLanguage].remove}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          {searchFilter ? (
            <Box style={invoiceListStyles.searchResultsBox}>
              <Typography style={invoiceListStyles.searchResultsFont}>
                {(language as any)[currentLanguage].result} "{searchFilter}"
              </Typography>
            </Box>
          ) : null}
        </Box>
      )
    }

    let columns = [
      {
        field: 'invoice',
        headerName: (language as any)[currentLanguage].invoice.toUpperCase(),
        minWidth: 200,
        editable: false
      },
      {
        field: 'customer',
        headerName: (language as any)[currentLanguage].customerText,
        minWidth: 350,
        editable: false,
        renderCell: (params: any) => {
          return (
            <Tooltip title={params.row.customer}>
              <Typography sx={invoiceListStyles.storeName}>{params.row.customer}</Typography>
            </Tooltip>
          )
        }
      },
      {
        field: 'orderedAt',
        headerName: (language as any)[currentLanguage].orderedAt.toUpperCase(),
        type: 'date',
        valueFormatter: (params: any) => {
          return getFormattedDate(params.value)
        },
        minWidth: 250,
        filterable: false,
        editable: false
      },
      {
        field: 'timeElapsed',
        headerName: (language as any)[currentLanguage].timeElapsed.toUpperCase(),
        valueFormatter: (params: any) => {
          return msToTime(params.value)
        },
        filterable: false,
        minWidth: 150,
        editable: false
      },
      {
        field: 'priority',
        headerName: (language as any)[currentLanguage].priorityInMins.toUpperCase(),
        renderCell: (params: any) => {
          const chipColor = getColorBasedOnServiceLevel(params.row.serviceLevel?.toUpperCase())
          return (
            <Chip
              style={{
                height: '20px',
                width: '160px',
                borderRadius: '3px',
                color: params.row.serviceLevel !== "AUTODASH" ? Colors.napaGrey2 : Colors.white,
                backgroundColor: isCompanyOwned
                  ? chipColor
                  : !!params.row.serviceLevel //for Independent stores- if serviceLevel is null, we use priority in min to determine color
                    ? chipColor
                    : params.row.priority
                      ? geColorBasedOnPriorityInMinutes(params.row.priority)
                      : '#E49600'
              }}
              label={
                isCompanyOwned
                  ? !!params.row.serviceLevel ? params.row.serviceLevel : 'SILVER'
                  : params.row.priority && params.row.serviceLevel
                    ? `${params.row.serviceLevel} - ${params.row.priority} mins`
                    : params.row.serviceLevel
                      ? `${params.row.serviceLevel}`
                      : params.row.priority
                        ? `${params.row.priority} mins`
                        : 'BRONZE'
              }
            />
          )
        },
        minWidth: 250,
        editable: false
      },
      {
        field: 'invoiceFlagged',
        headerName: (language as any)[currentLanguage].invoiceFlagged.toUpperCase(),
        filterable: false,
        minWidth: 150,
        editable: false,
        renderCell: renderFlaggedCell()
      },
      {
        field: 'autoDispatchTime',
        headerName: (language as any)[currentLanguage].autoDispatching,
        renderCell: (params: any) => {
          return params.row.autoDispatch || params.row.serviceLevel === 'AUTODASH' ? renderAutoDispatchedColumn(params) : ''
        },
        filterable: false,
        minWidth: 250,
        editable: false
      },
      {
        field: 'viewDetails',
        headerName: '',
        minWidth: 50,
        editable: false,
        filterable: false,
        renderCell: (params: any) => {
          return (
            <Button
              sx={invoiceListStyles.viewDetailsButton}
              onClick={async () => {
                setInvoiceDetails(params.row.invoiceDetails)
                setOpenEditInvoice(true)
              }}>
              {(language as any)[currentLanguage].viewDetails}
            </Button>
          )
        }
      }, {
        field: 'address',
        headerName: 'Location',
        filterable: false,
        minWidth: 250,
        editable: false,
        renderCell: (params: any) => {
          const location = params.row.location
          if (params.row.multipleAddress) {
            return <Box display={"flex"} flexDirection={"row"} gap={"8px"} alignItems={"center"}
              onClick={() => {
                if (params.row.multipleAddress) {
                  let invoceDetails: InvoicesResponse[] = []
                  invoceDetails.push(params.row.invoiceDetails)
                  setLocationInvoiceDetails(invoceDetails)
                  setIsBuildRoute(false)
                  setOpenLocationUpdate(true)
                }

              }}>
              {params.row.reqAddressUpdate
                ? <WarningIcon />
                : <CheckMarkIcon />
              }
              <Typography sx={invoiceListStyles.cellStyleParagaraph2} color={'#0066CB'}>Multiple Addresses</Typography>
            </Box>
          }
          return (
            <Box display={"flex"} flexDirection={"row"} gap={"8px"} alignItems={"center"}>
              <Grid display={"flex"} flexDirection={"column"}>
                <Typography sx={invoiceListStyles.cellStyleParagaraph2}>{location.addressLine1}</Typography>
                <Typography sx={invoiceListStyles.cellStyleParagaraph2}>{location.city}, {location.state} {location.zipCode}</Typography>
              </Grid>
            </Box>
          )
        }
      },
      {
        field: 'deliveryNote',
        headerName: 'DELIVERY NOTE',
        filterable: false,
        minWidth: 250,
        editable: false,
        renderCell: (params: any) => {
          return (
            <Tooltip title={params.row.notes}>
              <Typography sx={invoiceListStyles.storeName}>{params.row.notes}</Typography>
            </Tooltip>
          )
        }
      },
      {
        field: 'parts',
        headerName: 'PART #',
        minWidth: 0,
        editable: false
      },
      {
        field: 'partsDescription',
        headerName: 'PARTS DESC',
        filterable: false,
        minWidth: 0,
        editable: false
      }
    ]
    if (!autoDispatchEnabled) {
      columns = columns.filter((col) => col.field !== 'autoDispatchTime')
    }

    if (!isCompanyOwned || isApac) {
      columns = columns.filter((col) => col.field !== 'address')
    }

    const searchAllPartNumbers = (parts: any) => {
      let concated = ''
      parts.map((part: any) => {
        let partNumber = part.partNumber
        return (concated = concated + ' ' + partNumber)
      })
      return concated
    }

    const searchAllPartDescriptions = (parts: any) => {
      let concated = ''
      parts.map((part: any) => {
        let partDescription = part.description
        return (concated = concated + ' ' + partDescription)
      })
      return concated
    }

    const geColorBasedOnPriorityInMinutes = (priority: any) => {
      let colorCode = ''
      switch (true) {
        case priority <= 30:
          colorCode = '#D6D6D6'
          break
        case priority > 30 && priority <= 45:
          colorCode = '#FFE395'
          break
        case priority > 45 && priority <= 60:
          colorCode = '#ADADAD'
          break
        case priority > 60 && priority <= 75:
          colorCode = '#E49600'
          break
        case priority > 75:
          colorCode = ''
          break
      }
      return colorCode
    }

    const getColorBasedOnServiceLevel = (serviceLevel: string) => {
      let colorCode = '#ADADAD'
      switch (true) {
        case serviceLevel === 'PLATINUM':
          colorCode = '#D6D6D6'
          break
        case serviceLevel === 'GOLD':
          colorCode = '#FFE395'
          break
        case serviceLevel === 'SILVER':
          colorCode = '#ADADAD'
          break
        case serviceLevel === 'BRONZE':
          colorCode = '#E49600'
          break
        case serviceLevel === 'AUTODASH':
          colorCode = '#fe5600'
          break
      }
      return colorCode
    }

    const renderDataGrid = () => {
      return (
        <DataGrid
          style={{ height: `${windowSize.current[1] - 250}px` }}
          components={{
            Toolbar: QuickSearchToolbar,
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {(language as any)[currentLanguage].noResults}
              </Stack>
            ),
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {(language as any)[currentLanguage].noResults}
              </Stack>
            )
          }}
          disableColumnSelector={true}
          onStateChange={(state) => {
            const newRows = gridVisibleSortedRowIdsSelector(state)
            setFilteredInvoices(newRows)
          }}
          rows={
            displayInvoices?.map((invoice) => {
              return {
                id: isApac ? invoice.invoiceNumber : Number(invoice.invoiceNumber),
                invoice: invoice.invoiceNumber,
                customer: invoice.deliveryAddress.name,
                orderedAt: invoice.invoiceDateTime,
                priority: invoice.deliveryPriorityInMins,
                timeElapsed: Date.now() - invoice.invoiceDateTime,
                //At the moment, no delivery notes information exists on the invoice data object --
                // do we want to create a field where users can add notes to an invoice?
                deliveryNote: '',
                parts: searchAllPartNumbers(invoice.parts),
                partsDescription: searchAllPartDescriptions(invoice.parts),
                serviceLevel: invoice.serviceLevel,
                invoiceDetails: invoice,
                autoDispatch: invoice.autoDispatch,
                autoDispatchTime: fiveMin - Date.now() - invoice.invoiceDateTime,
                hold: invoice?.hold,
                invoiceDateTime: invoice.invoiceDateTime,
                location: invoice?.deliveryAddress,
                multipleAddress: invoice?.multipleAddresses,
                reqAddressUpdate: invoice?.requiresAddressUpdate,
              }
            }) || []
          }
          columns={
            currentTab === 'unassignedInvoices'
              ? columns.filter((column) => {
                return column.field !== 'invoiceFlagged'
              })
              : columns
          }
          checkboxSelection
          disableSelectionOnClick
          initialState={{
            sorting: {
              sortModel: [{ field: 'orderedAt', sort: 'desc' }]
            },
            columns: {
              columnVisibilityModel: {
                parts: false,
                partsDescription: false,
                deliveryNote: false
              }
            }
          }}
          hideFooterSelectedRowCount={true}
          disableVirtualization={process.env.NODE_ENV === 'test'}
          onSelectionModelChange={(newSelectionModel) => {
            interacted.current = true
            setSelectedInvoices(newSelectionModel)
            if (newSelectionModel && newSelectionModel.length === 0) {
              const selectedInvoiceData = displayInvoices?.find(
                (data) => +data.invoiceNumber === (isApac ? Number(newSelectionModel[0]) : newSelectionModel[0]))
              setSelectedInvoiceData(selectedInvoiceData ?? null)
              setStopAutoDash(true)
              setNowAutoDash(true)
              setIsDisabled(true)
              setBulkInvoices([])
            }
            else {
              const selectedInvoiceData = displayInvoices?.find(
                (data) => +data.invoiceNumber === (isApac ? Number(newSelectionModel[0]) : newSelectionModel[0]))
              setSelectedInvoiceData(selectedInvoiceData ?? null)
              setIsDisabled(newSelectionModel?.length === 0)
              const bulkInvoiceSelect = displayInvoices?.filter(x => newSelectionModel.includes(Number(x.invoiceNumber)))
              const isAutoDispatchDisabled = bulkInvoiceSelect && bulkInvoiceSelect?.length > 0 && bulkInvoiceSelect?.every(row => row.autoDispatch === true)
              setStopAutoDash(!isAutoDispatchDisabled)
              setNowAutoDash(newSelectionModel.length === 1 ? !selectedInvoiceData?.autoDispatch : true)

              if (newSelectionModel.length > 0 && selectedInvoiceData && bulkInvoiceSelect) {

                const bulkArray: BulkInvoices[] = bulkInvoiceSelect?.map(invoice => (
                  {
                    invoiceNumber: invoice.invoiceNumber,
                    invoiceDateTime: invoice.invoiceDateTime
                  }
                ))

                setBulkInvoices(bulkArray)
              }
            }
          }}
          selectionModel={selectedInvoices}
          sx={invoiceListStyles.gridSize}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 }
            }
          }}
        />
      )
    }
    return renderDataGrid()
    // eslint-disable-next-line
  }, [
    displayInvoices,
    selectedInvoices,
    setSelectedInvoices,
    setFilteredInvoices,
    currentLanguage,
    startDate,
    endDate,
    needsRefresh
  ])

  const NoInvoicesCard = () => {
    return (
      <Grid md={12} item>
        <DateFilter
          startDate={startDate}
          setStartDateFilter={setStartDateFilter}
          endDate={endDate}
          setEndDateFilter={setEndDateFilter}
          onSearch={onSearch}
          resetAll={resetAll}
        />
        <Card sx={invoiceListStyles.noInvoicesCard}>
          <CardContent>
            <div style={invoiceListStyles.noInvoicesCardContent}>
              <Typography sx={invoiceListStyles.noInvoices}>
                {(language as any)[currentLanguage].noInvoices}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>
    )
  }

  const renderSkeleton = () => {
    return (
      <>
        <Grid item xs={12}>
          <Skeleton variant={'text'} height={'52px'} animation={'wave'} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant={'text'} height={'52px'} animation={'wave'} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant={'text'} height={'52px'} animation={'wave'} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant={'text'} height={'52px'} animation={'wave'} />
        </Grid>
      </>
    )
  }

  const renderAutoDispatchedColumn = (params: {
    row: { timeElapsed: number; invoiceDateTime: number, serviceLevel: string, autoDispatch: boolean, hold: boolean }
  }) => {
    return (
      <Typography sx={invoiceListStyles.autoDispatch}>
        <AutoDispatchInfo
          timeElapsed={params.row.timeElapsed}
          invoiceDateTime={params.row.invoiceDateTime}
          wasHeld={!!params.row?.hold}
          autodashCancelled={!params.row?.autoDispatch && params.row?.serviceLevel === 'AUTODASH'}
        />
      </Typography>
    )
  }

  return (
    <Grid item container sx={invoiceListStyles.allInvoicesContainer}>
      {isLoadingInvoices ? (
        renderSkeleton()
      ) : (
        <Grid item container xs={12} sx={invoiceListStyles.dataGridStyle}>
          {/* <DateFilter /> */}
          {!isErrorInvoices ? ( //displayInvoices && displayInvoices.length > 0 && !isErrorInvoices
            InvoiceDataGrid
          ) : (
            <NoInvoicesCard />
          )}
        </Grid>
      )}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="xs" // Change this value to control the maximum width
        fullWidth
        sx={invoiceListStyles.dialogSize} // Custom width
      >
        <DialogTitle sx={invoiceListStyles.dialogTitle}>
          {(language as any)[currentLanguage].stopDispatchNow}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={invoiceListStyles.closeIcon}>
            <CloseIcon sx={invoiceListStyles.closeIconImg} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={invoiceListStyles.dialogContentText}>
            {(language as any)[currentLanguage].stopAutoDispatchDialogSubText}
          </DialogContentText>
          <FormLabel>Reason (required)</FormLabel>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="reason"
              name="reason"
              value={selectedReason}
              onChange={handleReasonChange}>
              <FormControlLabel
                value="Customer address hard to find"
                control={<Radio />}
                label={(language as any)[currentLanguage].reasonCustomerAddress}
              />
              <FormControlLabel
                value="Cash customer"
                control={<Radio />}
                label={(language as any)[currentLanguage].reasonCashCustomer}
              />
              <FormControlLabel
                value="Hazardous materials"
                control={<Radio />}
                label={(language as any)[currentLanguage].reasonHazardMaterials}
              />
              <FormControlLabel
                value="Transfer part"
                control={<Radio />}
                label={(language as any)[currentLanguage].reasonTransferPart}
              />
              <FormControlLabel
                value="Part doesn't meet size specifications"
                control={<Radio />}
                label={
                  <>
                    {' '}
                    {(language as any)[currentLanguage].reasonPartSpecs}
                    <Typography variant="body2" color="textSecondary">
                      {' '}
                      {(language as any)[currentLanguage].subReasnPartSpecs}
                    </Typography>{' '}
                  </>
                }
              />
              <FormControlLabel
                value="System error"
                control={<Radio />}
                label={(language as any)[currentLanguage].reasonSystemError}
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions sx={invoiceListStyles.dialogActions}>
          <Button
            onClick={handleCloseDialog}
            color="primary"
            variant="outlined"
            sx={invoiceListStyles.cancelButton}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirmStopAutoDispatch}
            color="primary"
            variant="contained"
            disabled={!selectedReason}
            sx={invoiceListStyles.stopAutoDispatchButton}>
            Stop Autodash
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default InvoiceList
