import { AxiosError } from 'axios'
import { AutoDispatchDetain, CustomStopDetail } from '../types/DeliveryRouteTypes'
import axiosInstance from '../helpers/AxiosHelper'

type RouteStatus = 'cancelled' | 'created' | 'pending' | 'in-progress' | 'complete'

type GetLatestRouteRequest = {
  storeNumber: string
  deliveryDriver: string
}

export type InvoicesResponse = {
  invoiceNumber: string
  customerNumber: string
  latitude: Number
  longitude: Number
  deliveryAddress: {
    name: string
    addressLine1: string
    addressLine2: string
    city: string
    state: string
    zipCode: string
    phoneNumber: string
    attn: string
  }
  anticipatedDeliveryTime: number
  invoiceDateTime: number
  deliveryStatus: boolean
  deliveryTimestamp: number
  deliveryPriorityInMins?: number | null
  priorityPlusCustomer: boolean
  autoDispatch: boolean
  parts: Part[]
  serviceLevel: string
  nssaInvoiceStatus: 'ON_HOLD' | 'INVOICED'
  hold: boolean
  invoiceNotes: Notes[]
  customerDropoffName: string
  customerDeliveryNotes: string
  locationName: string
  customerAddressId: string
  multipleAddresses?: boolean
  requiresAddressUpdate?: boolean
  referencePONumber: string
}

export type Notes = {
  sequenceNumber: number
  actualNote: string
}

export type DeliveryRoute = {
  suggestedRoute?: [];
  routeId: string
  storeNumber: string
  deliveryDriver: string
  routeStatus: RouteStatus
  stops: Stop[]
  routeCreationTimestamp: number
  routeCompletionTimestamp?: number
  departureTimestamp?: number
}

export type CancelRouteResponse = {
  status: string
  message: string
  timestamp: string
}

export type Stop = {
  customerNumber: string
  priorityPlusCustomer: boolean
  deliveryAddress: Address
  signatureCaptureImage: string
  signatureCaptureName: string
  deliveryTimestamp: number
  deliveryStatus: boolean
  invoices: Invoice[]
  expectedDriveDurationMinutes: number | null
  delayReason?: string[] | null
  delay: boolean
  delayNotes: string | null
  delayTimeInMinutes: number | null
  latitude: number
  longitude: number
  customStopName: string | null
  addressLine1: string | null
  addressLine2: string | null
  delivered: boolean
  phoneNumber: string | null
  city?: string
  state?: string
  zipcode?: string
  stopNotes: string | null
}

export type Invoice = {
  invoiceNumber: string
  customerNumber: string
  deliveryAddress: Address
  anticipatedDeliveryTime: string
  deliveryStatus: boolean
  invoiceDateTime: number
  deliveryPriorityInMins?: number | null
  deliveryTimestamp: number
  priorityPlusCustomer: boolean
  autoDispatch: boolean
  parts: Part[]
}

export type Address = {
  name: string
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  zipCode: string
  phoneNumber: string
  attn: string
}
type Part = {
  lineAbbreviation: string
  partNumber: string
  description: string
  requestedQuantity: number
  price: number
  net: number
  total: number
}

export type CreateRouteRequest = {
  storeNumber: string
  invoices: string[]
  deliveryDriver?: string | null
  routeStatus: string
}

export type UpdateRouteRequest = {
  storeNumber: string
  routeId: string
  addInvoicesMap: {
    [key: string]: string
  }
  removeInvoicesMap: {
    [key: string]: string
  }
  reorderStopsList: number[]
  removeInvoicesStopIdMap: {
    [key: string]: string
  }
  addCustomStopsList: CustomStopDetail[],
  removeCustomStopId: number[]
}

export type AssignRouteRequest = {
  routeId: string
  deliveryDriver?: string | null
}

export type GenericSuccessResponse = {
  status: string
  message: string
  timestamp: string
}

export type MarkDeliveredRequest = {
  nssaInvoiceStatus: string
  deliveredBy?: string
  reason: string
}

export type AutoDispatchRequest = {
  invoiceNumber: string | undefined
  nssaInvoiceStatus: string
  reason?: string | null
}

export type StoreTransferRequest = {
  storeNumber: string
  deliveryDriver: string | null
  routeStatus: string
  customStops: CustomStopDetail[]
}

export class DeliveryRouteService {
  static ROUTE_ENDPOINT = '/route'
  static ROUTE_ENDPOINT_V2 = '/v2/route'
  static ROUTE_ENDPOINT_V3 = '/route/v3'
  static ROUTE_ENDPOINT_V4 = '/route/v4'
  static INVOICE_ENDPOINT = '/invoice'
  static INVOICE_ENDPOINT_V2 = '/invoice/v2'
  static ROUTE_DOORDASH = '/externalDelivery/v2'

  static UNKNOWN_ERROR_MESSAGE = { message: 'Unknown error occurred, try again later' }

  static async getRoutes(
    storeNumber: string,
    startDate: string,
    endDate: string,
    withPhotoPOD: boolean
  ): Promise<DeliveryRoute[]> {
    try {
      let url = `${this.ROUTE_ENDPOINT_V4}/storeNumber/${storeNumber}?withPhotoPOD=${withPhotoPOD}`
      if (startDate && endDate) url = url + `&startDate=${startDate}&endDate=${endDate}`
      else url = url + `&hoursAgo=72`
      const response = await axiosInstance.get<DeliveryRoute[]>(url)
      if (response.status === 200) {
        return response.data
      }
      return Promise.reject(`[${response.status}]: ${response.data}`)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  // We don't use it today
  static async getLatestRoute(request: GetLatestRouteRequest): Promise<DeliveryRoute | null> {
    try {
      const response = await axiosInstance.get<DeliveryRoute>(
        `${this.ROUTE_ENDPOINT_V3}/active/storeNumber/${request.storeNumber}/deliveryDriver/${request.deliveryDriver}`
      )

      return response.data
    } catch (e: AxiosError | Error | unknown) {
      return null
    }
  }

  // We don't use it today
  static async getAllRoutesForDriver(
    storeNumber: string,
    deliveryDriver: string
  ): Promise<DeliveryRoute[] | null> {
    try {
      const response = await axiosInstance.get<DeliveryRoute[]>(
        //TODO: V3?
        `${this.ROUTE_ENDPOINT_V3}/storeNumber/${storeNumber}?deliveryDriver=${deliveryDriver}`
      )

      return response.data
    } catch (e: AxiosError | Error | unknown) {
      return null
    }
  }

  static async getRouteByRouteId(routeId: string): Promise<DeliveryRoute> {
    try {
      const response = await axiosInstance.get<DeliveryRoute>(
        `${this.ROUTE_ENDPOINT_V4}/id/${routeId}?withRoutePlayback=true&withSuggestedRoute=true`
      )
      return response.data
    } catch (e: AxiosError | Error | unknown) {
      return Promise.reject(e)
    }
  }

  static async assignRoute(request: AssignRouteRequest): Promise<void> {
    try {
      await axiosInstance.post<void>(`${this.ROUTE_ENDPOINT_V3}/assign`, request)
    } catch (e: AxiosError | Error | unknown) {
      console.log('Error assigning route', e)
      return Promise.reject(e)
    }
  }

  static async getInvoicesByStore(
    storeNumber: String,
    startDate: number | null,
    endDate: number | null
  ): Promise<InvoicesResponse[]> {
    let url = `${this.INVOICE_ENDPOINT_V2}/storeNumber/${storeNumber}?startDate=${startDate}&endDate=${endDate}`
    try {
      const response = await axiosInstance.get(
        url // need endpoint to only get unassigned invoices
      )
      return response.data
    } catch (e: AxiosError | Error | unknown) {
      if (e instanceof AxiosError) {
        switch (e.response?.data?.status) {
          case 'NOT_FOUND':
            return []
          default:
            return Promise.reject(DeliveryRouteService.UNKNOWN_ERROR_MESSAGE)
        }
      } else {
        return Promise.reject(DeliveryRouteService.UNKNOWN_ERROR_MESSAGE)
      }
    }
  }

  static async manualAddInvoice(
    storeNumber: String,
    invoiceNumber: string
  ): Promise<InvoicesResponse[]> {
    try {
      const response = await axiosInstance.get(
        `${this.INVOICE_ENDPOINT_V2}/storeNumber/${storeNumber}/invoiceNumber/${invoiceNumber}`
      )
      return response.data
    } catch (e: AxiosError | Error | unknown) {
      if (e instanceof AxiosError) {
        return Promise.reject({
          message: e.response?.data.message,
          status: e.response?.data.status
        })
      } else {
        return Promise.reject()
      }
    }
  }

  static async createRoute(createRouteRequest: CreateRouteRequest): Promise<DeliveryRoute> {
    try {
      return await axiosInstance.post(
        `${this.ROUTE_ENDPOINT_V4}/dispatchCreateRoute`,
        createRouteRequest
      )
    } catch (e: AxiosError | Error | unknown) {
      if (e instanceof AxiosError) {
        console.log(e.response)
        return Promise.reject({
          message: e.response?.data.message ? e.response.data.message : e.message
        })
      }
      return Promise.reject()
    }
  }

  static async createExternalDelivery(
    createRouteRequest: any,
    storeNumber: string,
    invoiceNumber: string
  ): Promise<any> {
    try {
      const response = await axiosInstance.post(
        `${this.ROUTE_DOORDASH}/createQuote`,
        createRouteRequest
      )
      return response?.data
    } catch (e: AxiosError | Error | unknown) {
      if (e instanceof AxiosError) {
        console.log(e.response?.data?.message)
        return Promise.reject({ message: e.response?.data?.message })
      } else {
        return Promise.reject()
      }
    }
  }

  static async acceptExternalDelivery(
    createRouteRequest: any,
    externalDeliveryId: string
  ): Promise<any> {
    try {
      return await axiosInstance.post(`${this.ROUTE_DOORDASH}/acceptQuote`, createRouteRequest)
    } catch (e: AxiosError | Error | unknown) {
      if (e instanceof AxiosError) {
        console.log(e.response)
        return Promise.reject({ message: e.response?.data?.message })
      } else {
        return Promise.reject()
      }
    }
  }

  static async cancelExternalDelivery(externalDeliveryId: string): Promise<any> {
    try {
      const response = await axiosInstance.post(
        `${this.ROUTE_DOORDASH}/cancelDelivery/${externalDeliveryId}`,
        null
      )
      return response.data
    } catch (e: AxiosError | Error | unknown) {
      if (e instanceof AxiosError) {
        console.log(e.response)
        return Promise.reject({ message: e.response?.data?.message })
      } else {
        return Promise.reject()
      }
    }
  }

  static async getStoreAddress(
    storeNumber: String,
    invoiceNumber: string
  ): Promise<InvoicesResponse[]> {
    try {
      const response = await axiosInstance.get(
        `${this.INVOICE_ENDPOINT_V2}/latest/storeAddress/storeNumber/${storeNumber}/invoiceNumber/${invoiceNumber}`
      )
      return response.data
    } catch (e: AxiosError | Error | unknown) {
      if (e instanceof AxiosError) {
        return Promise.reject({
          message: e.response?.data.message,
          status: e.response?.data.status
        })
      } else {
        return Promise.reject()
      }
    }
  }

  static async addInvoice(addInvoiceRequest: {
    routeId: string
    invoiceNumber: string
    storeNumber: string
  }): Promise<void> {
    try {
      await axiosInstance.post(`${this.ROUTE_ENDPOINT_V4}/addInvoice`, addInvoiceRequest)
      return
    } catch (e: AxiosError | Error | unknown) {
      if (e instanceof AxiosError) {
        return Promise.reject({ message: e.response?.data.message })
      } else {
        return Promise.reject()
      }
    }
  }

  static async removeInvoice(
    routeId: string,
    storeNumber: string,
    invoiceNumber: string,
    stopId: string
  ): Promise<void> {
    try {
      await axiosInstance.put<void>(
        `${this.ROUTE_ENDPOINT_V4}/removeInvoice/id/${routeId}/storeNumber/${storeNumber}/invoiceNumber/${invoiceNumber}?stopId=${stopId}`,
        null
      )
    } catch (e: AxiosError | Error | unknown) {
      console.log('Error removing invoice', e)
      return Promise.reject(e)
    }
  }

  static async cancelRoute(routeId: string): Promise<void> {
    try {
      await axiosInstance.post<CancelRouteResponse>(
        `${this.ROUTE_ENDPOINT_V4}/cancel/${routeId}`,
        null
      )
    } catch (e: AxiosError | Error | unknown) {
      console.log('Error cancelling the route', e)
      return Promise.reject(e)
    }
  }

  static async dispatchUpdateRoute(updateRouteRequest: UpdateRouteRequest): Promise<void> {
    try {
      await axiosInstance.post<GenericSuccessResponse>(
        `${this.ROUTE_ENDPOINT_V4}/dispatchUpdateRoute`,
        updateRouteRequest
      )
    } catch (e: any) {
      console.log('Error updating the route', e)
      return Promise.reject(e)
    }
  }

  static async updateInvoiceStatus(
    updateRequest: any,
    storeNumber: string
  ): Promise<void> {
    let url = `${this.INVOICE_ENDPOINT_V2}/v2/storeNumber/${storeNumber}`

    try {
      await axiosInstance.patch<void>(url, updateRequest)
    } catch (e: AxiosError | Error | unknown) {
      return Promise.reject(e)
    }
  }

  static async updateDeliveryStatus(
    request: MarkDeliveredRequest,
    storeNumber: string,
    invoiceNumber: string | undefined,
    invoiceDateTime: number | undefined
  ): Promise<void> {
    let url = `${this.INVOICE_ENDPOINT_V2}/storeNumber/${storeNumber}/invoiceNumber/${invoiceNumber}/invoiceDateTime/${invoiceDateTime}`

    try {
      await axiosInstance.patch<void>(url, request)
    } catch (e: AxiosError | Error | unknown) {
      console.log('Error updating the invoice', e)
      return Promise.reject(e)
    }
  }

  static async stopAutodispatch(
    updateRequest: AutoDispatchRequest,
    storeNumber: string,
    invoiceNumber: string,
    invoiceDateTime: number,
    reason: string
  ): Promise<void> {
    let url = `${this.INVOICE_ENDPOINT_V2}/stopAutodispatch/storeNumber/${storeNumber}/invoiceNumber/${invoiceNumber}/invoiceDateTime/${invoiceDateTime}?reason=${reason}`

    try {
      await axiosInstance.patch<GenericSuccessResponse>(url, updateRequest)
    } catch (e: any) {
      console.log('Error updating the route', e)
      return Promise.reject(e)
    }
  }

  static async createRouteAndDelivery(autoDispatchDetain: AutoDispatchDetain): Promise<any> {
    let url = `${this.ROUTE_DOORDASH}/createRouteAndDelivery`
    try {
      const resp = await axiosInstance.post<GenericSuccessResponse>(
        url,
        {
          ...autoDispatchDetain
        }
      )
      return Promise.resolve(resp)
    } catch (e: any) {
      console.log('Error updating the route', e)
      return Promise.reject(e)
    }
  }

  static async dispatchStoreTransfer(request: StoreTransferRequest): Promise<any> {
    try {
      let url = `${this.ROUTE_ENDPOINT_V4}/dispatchCreateStoreTransfer`
      const response = await axiosInstance.post<GenericSuccessResponse>(url, request)
      if (response.status === 200) {
        return response.data
      }
      return Promise.reject(`[${response.status}]: ${response.data}`)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
